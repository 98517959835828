<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <h2 class="brand-text text-primary ml-1">FLOW CHATTING</h2>
        </b-link>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label-for="email" label="帳號">
              <b-form-input
                id="email"
                v-model="username"
                name="login-email"
                autofocus
              />
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">密碼</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                記住我
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" type="submit" block :disabled="invalid">
              登入
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "@axios";
import useJwt from "@/auth/jwt/useJwt";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import store from "@/store";

// SignalR
import { hubConnect } from "@/hubs/hub";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: "",
      password: "",
      status: "",
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.$refs.loginForm
        .validate()
        .then((isSuccess) => {
          if (!isSuccess) {
            throw new Error("帳號密碼驗證規則失敗");
          }
          return useJwt.login({
            loginForm: {
              username: this.username,
              password: this.password,
            },
          });
        })
        .then((response) => {
          if (!response.data.token) {
            throw new Error("沒有拿到 token");
          }
          const accessToken = response.data.token;
          useJwt.setToken(accessToken);
          return axios.post(
            "https://flowapi.theflowchat.com/platform/token_data",
            {},
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          );
        })
        .then((response) => {
          hubConnect();
          store.dispatch("chat/fetchContactsAndLastChat");
          
          const userInfo = response.data.userList;
          const userData = {
            id: 1,
            customId: userInfo.custom_id,
            fullName: userInfo.name,
            // eslint-disable-next-line global-require
            avatar: userInfo.avatar,
            extras: {
              eCommerceCartItemsCount: 5,
            },
            role: "admin",
            ability: [{ action: "manage", subject: "all" }],
          };

          localStorage.setItem("userData", JSON.stringify(userData));
          this.$ability.update(userData.ability);
          this.$router.replace(getHomeRouteForLoggedInUser(userData.role));
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
